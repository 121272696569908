/* src/components/FAQs/FAQs.css */

.faq-section {
    position: relative;
    padding: 4rem 1rem;
    overflow: hidden;
    background-color: #02053c; /* Dark background matching SectionA */
    color: #ffffff; /* White text for contrast */
    font-family: 'Manrope', sans-serif;
  }
  
  .faq-container {
    text-align: center;
    padding: 20px;
    background-color: rgba(21, 12, 66, 0.85); /* Semi-transparent dark background */
    border-radius: 15px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.5);
    max-width: 900px;
    margin: auto;
    position: relative;
    z-index: 1;
  }
  
  .faq-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 150%;
    height: 150%;
    background: radial-gradient(circle at center, rgba(0, 198, 255, 0.2), transparent 70%);
    transform: translateX(-50%);
    z-index: -1;
    border-radius: 50%;
    animation: pulse 10s infinite;
  }
  
  @keyframes pulse {
    0% {
      transform: translateX(-50%) scale(1);
      opacity: 0.4;
    }
    50% {
      transform: translateX(-50%) scale(1.2);
      opacity: 0.2;
    }
    100% {
      transform: translateX(-50%) scale(1);
      opacity: 0.4;
    }
  }
  
  /* Accordion Styles */
  .MuiAccordion-root {
    transition: background-color 0.3s;
  }
  
  .MuiAccordion-root:hover {
    background-color: rgba(0, 198, 255, 0.1);
  }
  
  .faq-container .MuiAccordionSummary-root {
    background-color: rgba(0, 198, 255, 0.2);
    border-radius: 10px;
  }
  
  .faq-container .MuiAccordionDetails-root {
    background-color: rgba(21, 12, 66, 0.85);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  .faq-container .MuiTypography-root {
    color: #ffffff;
  }
  
  /* Button Styles */
  .faq-container .MuiButton-contained {
    background: linear-gradient(45deg, #00c6ff, #0072ff);
    color: #fff;
    border-radius: 25px;
    padding: 0.75rem 2rem;
    margin-bottom: 1.5rem;
    font-weight: 600;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    transition: background 0.3s, transform 0.3s;
  }
  
  .faq-container .MuiButton-contained:hover {
    background: linear-gradient(45deg, #0056b3, #003f7f);
    transform: translateY(-5px);
  }
  
  /* Typography Styles */
  .faq-container h2 {
    font-size: 2rem;
    color: #00c6ff;
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
  }
  
  .faq-container p {
    font-size: 1rem;
    color: #a0a0a0;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .faq-container {
      padding: 15px;
    }
  
    .faq-container h2 {
      font-size: 2rem;
    }
  
    .faq-container p {
      font-size: 1rem;
    }
  
    .faq-container .MuiButton-contained {
      width: 100%;
      padding: 0.75rem 1rem;
      font-size: 14px;
    }
  }
  