/* src/components/Users/Users.css */

/* Container for Users */
.users-container {
    padding: 40px 20px;
    background-color: #121212; /* Dark background */
    min-height: 100vh;
  }
  
  /* Loading Spinner Container */
  .users-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    background-color: #121212;
  }
  
  /* Wrapper for each User Card to handle hover scaling */
  .user-card-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* User Card Styling */
  .user-card {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background-color: #1e1e1e; /* Slightly lighter dark background */
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  /* User Card Image */
  .user-card-media {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    object-fit: cover;
  }
  
  /* User Card Content */
  .user-card-content {
    flex-grow: 1;
    padding: 16px;
    display: flex;
    flex-direction: column;
  }
  
  /* User Card Title */
  .user-card-title {
    color: #ffffff;
    font-weight: 700;
    margin-bottom: 8px;
  }
  
  /* User Card Description */
  .user-card-description {
    color: #bbbbbb;
    margin-bottom: 12px;
    flex-grow: 1;
  }
  
  /* User Card Information */
  .user-card-info {
    color: #dddddd;
    margin-bottom: 6px;
  }
  
  /* User Card Action (Chat Button) */
  .user-card-action {
    padding: 16px;
    text-align: center;
  }
  
  /* Chat Button Styling */
  .chat-button {
    background: linear-gradient(45deg, #00c6ff, #0072ff);
    border: none;
    border-radius: 20px;
    padding: 8px 24px;
    font-size: 14px;
    font-weight: 600;
    transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
  }
  
  .chat-button:hover {
    background: linear-gradient(45deg, #0072ff, #0056b3);
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 198, 255, 0.4);
  }
  
  .chat-button:active {
    transform: translateY(0);
    box-shadow: 0 4px 8px rgba(0, 198, 255, 0.3);
  }
  
  /* Responsive Adjustments */
  @media (max-width: 1024px) {
    .users-container {
      padding: 30px 15px;
    }
  }
  
  @media (max-width: 768px) {
    .user-card {
      border-radius: 12px;
    }
  
    .user-card-media {
      height: 180px;
    }
  
    .chat-button {
      padding: 8px 20px;
      font-size: 13px;
    }
  }
  
  @media (max-width: 480px) {
    .users-container {
      padding: 20px 10px;
    }
  
    .user-card-media {
      height: 150px;
    }
  
    .chat-button {
      padding: 6px 16px;
      font-size: 12px;
    }
  }
  