/* src/components/Footer/Footer.css */

/* Footer Container */
.footer {
    background-color: #02053c; /* Dark background */
    color: #ffffff; /* White text for contrast */
    padding: 40px 0;
    margin-top: 5rem;
    font-family: 'Manrope', sans-serif;
    position: relative;
    overflow: hidden;
  }
  
  /* Logo Styling */
  .footer-logo {
    width: 65px;
    height: 65px;
    margin-bottom: 1rem;
  }
  
  .footer-copy {
    font-size: 0.875rem;
    color: #a0a0a0;
  }
  
  /* Navigation Links */
  .footer-links-section {
    text-align: center;
  }
  
  .footer-link {
    color: #ffffff;
    text-decoration: none;
    font-weight: 600;
    font-size: 1rem;
    position: relative;
    transition: color 0.3s;
  }
  
  .footer-link::after {
    content: '';
    position: absolute;
    width: 0%;
    height: 2px;
    bottom: -4px;
    left: 0;
    background-color: #00c6ff;
    transition: width 0.3s;
  }
  
  .footer-link:hover {
    color: #00c6ff;
  }
  
  .footer-link:hover::after {
    width: 100%;
  }
  
  /* Social Media Icons */
  .footer-social-section {
    text-align: center;
  }
  
  .footer-social-link {
    color: #ffffff;
    transition: color 0.3s, transform 0.3s;
  }
  
  .footer-social-link:hover {
    color: inherit; /* Colors are handled by Framer Motion */
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .footer {
      padding: 30px 0;
    }
  
    .footer-copy {
      font-size: 0.75rem;
    }
  
    .footer-link {
      font-size: 0.9rem;
    }
  }
  