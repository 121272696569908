/* ContactUs.css */

/* Reset some basic elements */
.contact-us-container {
    padding: 4rem 2rem;
    background-color: #001f3f; /* Dark Navy Background */
    color: #ffffff; /* White Text */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: fadeIn 1s ease-in;
  }
  
  /* Title Styling */
  .contact-us-title {
    font-size: 2.5rem;
    margin-bottom: 3rem;
    font-family: 'Poppins', sans-serif;
    animation: slideDown 1s ease-out;
  }
  
  /* QR Codes Section */
  .qr-codes-section {
    display: flex;
    gap: 3rem;
    margin-bottom: 4rem;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .qr-code {
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: zoomIn 1s ease-out;
  }
  
  .qr-image {
    width: 200px;
    height: 200px;
    border: 2px solid #ffffff;
    border-radius: 10px;
    margin-bottom: 1rem;
    transition: transform 0.3s ease;
  }
  
  .qr-image:hover {
    transform: scale(1.05);
  }
  
  .qr-description {
    font-size: 1rem;
    text-align: center;
    font-family: 'Poppins', sans-serif;
  }
  
  /* Contact Information */
  .contact-info {
    display: flex;
    align-items: center;
    margin-bottom: 4rem;
    animation: fadeInUp 1s ease-out;
  }
  
  .contact-icon {
    font-size: 2rem;
    margin-right: 1rem;
    color: #ffcc00; /* Accent Color */
    transition: color 0.3s ease;
  }
  
  .contact-icon:hover {
    color: #ffffff;
  }
  
  .contact-email {
    font-size: 1.2rem;
    color: #ffffff;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    transition: color 0.3s ease;
  }
  
  .contact-email:hover {
    color: #ffcc00; /* Accent Color on Hover */
  }
  
  /* Social Media Links */
  .social-media-section {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: center;
    animation: fadeInUp 1.2s ease-out;
  }
  
  .social-media-link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #ffffff;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-size: 1.1rem;
    transition: color 0.3s ease, transform 0.3s ease;
  }
  
  .social-media-link:hover {
    color: #ffcc00; /* Accent Color */
    transform: translateY(-5px);
  }
  
  .social-icon {
    font-size: 1.5rem;
    transition: color 0.3s ease;
  }
  
  .social-media-link:hover .social-icon {
    color: #ffcc00; /* Accent Color on Hover */
  }
  
  /* Animations */
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes slideDown {
    from { transform: translateY(-50px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }
  
  @keyframes zoomIn {
    from { transform: scale(0.8); opacity: 0; }
    to { transform: scale(1); opacity: 1; }
  }
  
  @keyframes fadeInUp {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .qr-codes-section {
      flex-direction: column;
      gap: 2rem;
    }
    
    .qr-image {
      width: 150px;
      height: 150px;
    }
    
    .contact-email {
      font-size: 1rem;
    }
    
    .social-media-link {
      font-size: 1rem;
    }
    
    .social-icon {
      font-size: 1.3rem;
    }
  }
  