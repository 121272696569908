/* Menu.css */

/* Reset default margins and paddings */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Menu Wrapper */
.menu-wrapper {
  position: relative;
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  background-color: #001f3f; /* Dark Navy Background */
}

/* Hamburger Menu Button */
.menu-icon {
  width: 40px;
  height: 40px;
  position: relative;
  cursor: pointer;
  color: #ffffff; /* White Color for Icons */
  z-index: 1001; /* Ensure it's above the navigation and overlay */
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease-in-out;
}

.menu-icon:hover {
  transform: scale(1.1);
}

.menu-icon-svg {
  font-size: 2rem;
}

.close-icon {
  font-size: 2rem;
}

/* Mobile Navigation Menu */
.menu-navigation {
  position: fixed;
  top: 0;
  right: -100%;
  height: 100%;
  width: 80%;
  max-width: 300px;
  background-color: #001f3f; /* Dark Navy */
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 0.3s ease-in-out;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-navigation.open {
  right: 0;
}

/* Navigation Links */
.menu-navigation ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.menu-navigation li {
  margin: 1.5rem 0;
}

.menu-link {
  color: #ffffff; /* White Text */
  font-size: 1.2rem;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
  transition: color 0.3s ease, transform 0.3s ease;
}

.menu-link:hover {
  color: #ffcc00; /* Accent Color on Hover */
  transform: translateX(10px);
}

/* Menu Overlay */
.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent Black */
  z-index: 999; /* Just below the menu-navigation */
  animation: fadeIn 0.3s ease-in-out;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Responsive Design */
@media (min-width: 938px) {
  .menu-wrapper {
    display: none; /* Hide mobile menu on larger screens */
  }
}

@media (max-width: 938px) {
  .menu-navigation {
    width: 100%;
    max-width: none;
  }
}

/* Additional Enhancements */

/* Smooth slide-in effect for menu items */
.menu-navigation ul li a {
  display: block;
  opacity: 0;
  transform: translateX(20px);
  animation: slideIn 0.5s forwards;
}

.menu-navigation.open ul li a:nth-child(1) {
  animation-delay: 0.2s;
}

.menu-navigation.open ul li a:nth-child(2) {
  animation-delay: 0.4s;
}

.menu-navigation.open ul li a:nth-child(3) {
  animation-delay: 0.6s;
}

.menu-navigation.open ul li a:nth-child(4) {
  animation-delay: 0.8s;
}

.menu-navigation.open ul li a:nth-child(5) {
  animation-delay: 1s;
}

.menu-navigation.open ul li a:nth-child(6) {
  animation-delay: 1.2s;
}

@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
