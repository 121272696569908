/* src/components/Features/Features.css */

.features-section {
    position: relative;
    padding: 4rem 1rem;
    overflow: hidden;
    background-color: #02053c; /* Dark background matching SectionA */
    color: #ffffff; /* White text for contrast */
    font-family: 'Manrope', sans-serif;
  }
  
  .features-container {
    text-align: center;
    padding: 20px;
    background-color: rgba(21, 12, 66, 0.85); /* Semi-transparent dark background */
    border-radius: 15px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.5);
    max-width: 1200px;
    margin: auto;
    position: relative;
    z-index: 1;
  }
  
  /* Feature Card Styles */
  .feature-card {
    background-color: rgba(21, 12, 66, 0.95); /* Slightly more opaque for better readability */
    color: #ffffff;
    border: 1px solid #00c6ff;
    border-radius: 10px;
    padding: 2rem 1rem;
    transition: transform 0.3s, box-shadow 0.3s;
    cursor: pointer;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .feature-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 30px rgba(0, 198, 255, 0.5);
  }
  
  .icon-container {
    background: linear-gradient(135deg, #00c6ff, #0072ff);
    padding: 1rem;
    border-radius: 50%;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .feature-icon {
    color: #fff;
  }
  
  .feature-title {
    font-weight: 700;
    color: #00c6ff;
    margin-bottom: 0.5rem;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  }
  
  .feature-description {
    color: #a0a0a0;
    font-size: 1rem;
    text-align: center;
    white-space: pre-line;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .features-container {
      padding: 15px;
    }
  
    .feature-title {
      font-size: 1.25rem;
    }
  
    .feature-description {
      font-size: 0.95rem;
    }
  
    .icon-container {
      padding: 0.75rem;
      margin-bottom: 0.75rem;
    }
  }
  