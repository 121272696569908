/* Auth.css */

/* Reset some basic elements */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Container Styles */
.auth-section {
  min-height: 100vh;
  background: linear-gradient(45deg, #007bff, #6610f2);
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.auth-input-label {
  font-size: 17px;
}

/* Auth Container */
.auth-container {
  background: rgba(255, 255, 255, 0.9);
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(16, 24, 40, 0.1);
  width: 100%;
  max-width: 500px;
  animation: fadeInUp 0.8s ease-out;
}

/* Logo Styles */
.auth-container .logo {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
}

.auth-container .logo img {
  width: 65px;
  height: 65px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.auth-container .logo img:hover {
  transform: scale(1.05);
}

/* Heading Styles */
.auth-container h1 {
  text-align: center;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0.5rem;
  color: #333333;
}

.auth-container p {
  text-align: center;
  color: gray;
  font-size: 14px;
  margin-bottom: 1.5rem;
  font-family: 'Poppins', sans-serif;
}

/* Button Styles */
.auth-container button {
  width: 100%;
  height: 44px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.auth-container button.google-btn {
  background: white;
  color: rgba(78, 86, 109, 1);
  border: 1px solid rgba(230, 232, 236, 1);
}

.auth-container button.google-btn:hover {
  background: #f0f0f0;
  transform: translateY(-2px);
}

.auth-container button.primary-btn {
  background: linear-gradient(45deg, #007bff, #6610f2);
  color: white;
  border: none;
}

.auth-container button.primary-btn:hover {
  background: linear-gradient(45deg, #0056b3, #5400c1);
  transform: translateY(-2px);
}

/* Text with Lines */
.text-with-lines {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 1.5rem 0;
}

.text-with-lines::before,
.text-with-lines::after {
  content: '';
  flex: 1;
  height: 1px;
  background: #cccccc;
}

.text-with-lines span {
  padding: 0 10px;
  color: #666666;
  font-family: 'Poppins', sans-serif;
}

/* Input Styles */
.auth-container input {
  width: 95%;
  height: 44px;
  padding: 0 1rem;
  border: 1px solid rgba(230, 232, 236, 1);
  border-radius: 8px;
  font-size: 16px;
  color: rgba(36, 36, 54, 1);
  font-family: 'Poppins', sans-serif;
  margin-bottom: 1rem;
  transition: border-color 0.3s ease;
}

.auth-container input:focus {
  outline: none;
  border-color: #007bff;
}

/* Remember Me and Forgot Password */
.remember-forgot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  font-family: 'Poppins', sans-serif;
}

.remember-forgot input[type="checkbox"] {
  margin-right: 0.5rem;
}

.remember-forgot button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  transition: color 0.3s ease;
}

.remember-forgot button:hover {
  color: #0056b3;
}

/* Signup and Signin Links */
.auth-container p {
  font-size: 14px;
  color: rgba(78, 86, 109, 1);
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

.auth-container p button {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-weight: 600;
  margin-left: 5px;
  font-family: 'Poppins', sans-serif;
  transition: color 0.3s ease;
}

.auth-container p button:hover {
  color: #0056b3;
}

/* Uploaded Files Section */
.uploaded-files {
  background-color: #e9ecef;
  font-size: 12px;
  padding: 0.5rem;
  border-radius: 8px;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Poppins', sans-serif;
}

.uploaded-files span {
  color: black;
}

.uploaded-files .remove-icon {
  color: red;
  cursor: pointer;
  transition: color 0.3s ease;
}

.uploaded-files .remove-icon:hover {
  color: darkred;
}

/* Google Map Section */
.map-section {
  height: 200px;
  width: 100%;
  margin-top: 1rem;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(16, 24, 40, 0.1);
}

/* Animations */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateX(-100%); opacity: 0; }
  to { transform: translateX(0); opacity: 1; }
}

/* Responsive Design */
@media (max-width: 800px) {
  .auth-container {
    padding: 1.5rem;
    width: 90%;
  }

  .auth-container h1 {
    font-size: 17px;
  }

  .auth-container p {
    font-size: 13px;
  }

  .auth-container button {
    font-size: 13px;
    height: 40px;
  }

  .text-with-lines::before,
  .text-with-lines::after {
    width: 100px;
  }

  .remember-forgot {
    flex-direction: column;
    align-items: flex-start;
  }

  .remember-forgot button {
    margin-top: 0.5rem;
  }

  .uploaded-files {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media (max-width: 480px) {
  .auth-container {
    padding: 1rem;
    width: 95%;
  }

  .auth-container h1 {
    font-size: 18px;
  }

  .auth-container p {
    font-size: 10px;
  }

  .auth-container button {
    font-size: 12px;
    height: 38px;
  }

  .text-with-lines::before,
  .text-with-lines::after {
    width: 80px;
  }

  .uploaded-files {
    font-size: 10px;
  }

  .uploaded-files span {
    font-size: 10px;
  }

  .uploaded-files .remove-icon {
    font-size: 16px;
  }
}
