/* Nav.css */

/* Reset default margins and paddings */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Navbar Styles */
  .navbar {
    width: 100%;
    background-color: #001f3f; /* Dark Navy */
    color: #ffffff; /* White Text */
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    animation: fadeInDown 0.5s ease-out;
  }
  
  .navbar-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0.75rem 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  /* Logo Styles */
  .logo {
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  
  .logo:hover {
    transform: scale(1.05);
  }
  
  .logo-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  /* Navigation Links */
  .nav-links {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }
  
  .nav-link {
    color: #ffffff;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    position: relative;
    transition: color 0.3s ease;
  }
  
  .nav-link::after {
    content: '';
    display: block;
    width: 0%;
    height: 2px;
    background: #ffffff;
    transition: width 0.3s;
    position: absolute;
    bottom: -5px;
    left: 0;
  }
  
  .nav-link:hover {
    color: #ffcc00; /* Accent Color on Hover */
  }
  
  .nav-link:hover::after {
    width: 100%;
  }
  
  /* User Actions */
  .user-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  
  .person-icon {
    font-size: 30px;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .person-icon:hover {
    color: #ffcc00; /* Accent Color on Hover */
  }
  
  .auth-buttons {
    display: flex;
    gap: 0.5rem;
  }
  
  .auth-button {
    padding: 0.5rem 1rem;
    border-radius: 8px;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 14px;
    transition: background 0.3s ease, transform 0.3s ease;
    cursor: pointer;
  }
  
  .buyer-login {
    background: #ffffff;
    color: #001f3f;
    border: 2px solid #ffffff;
  }
  
  .buyer-login:hover {
    background: #ffcc00; /* Accent Color on Hover */
    color: #001f3f;
    transform: translateY(-2px);
  }
  
  .seller-login {
    background: linear-gradient(45deg, #007bff, #6610f2);
    color: #ffffff;
    border: none;
  }
  
  .seller-login:hover {
    background: linear-gradient(45deg, #0056b3, #5400c1);
    transform: translateY(-2px);
  }
  

  .change .bar:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }
  
  .change .bar:nth-child(2) {
    opacity: 0;
  }
  
  .change .bar:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }
  
  /* Auth Popup Styles (Optional) */
  .auth-popup {
    /* Add your popup styles here */
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .nav-links {
      display: none;
      width: 100%;
      flex-direction: column;
      gap: 1rem;
      background-color: #001f3f;
      padding: 1rem;
      position: absolute;
      top: 60px; /* Height of navbar */
      left: 0;
      animation: slideIn 0.3s forwards;
    }
  
    .nav-links.active {
      display: flex;
    }
  
    .hamburger-menu {
      display: flex;
    }
  
    .user-actions {
      display: none;
    }
  }
  
  /* Animations */
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  