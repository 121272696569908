/* MonetizationStrategy.css */

/* Container Styles */
.monetization-container {
    padding: 4rem 2rem;
    background-color: #001f3f; /* Dark Navy Background */
    color: #ffffff; /* White Text */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: fadeIn 1s ease-in;
  }
  
  /* Title Styles */
  .monetization-title {
    font-size: 3rem;
    margin-bottom: 2rem;
    font-family: 'Poppins', sans-serif;
    text-align: center;
  }
  
  /* Description Styles */
  .monetization-description {
    font-size: 1.2rem;
    max-width: 800px;
    text-align: center;
    margin-bottom: 3rem;
    font-family: 'Poppins', sans-serif;
    line-height: 1.6;
  }
  
  /* Subtitle Styles */
  .monetization-subtitle {
    font-size: 2rem;
    margin-bottom: 2rem;
    font-family: 'Poppins', sans-serif;
    text-align: center;
  }
  
  /* Income Sources Container */
  .income-sources {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    width: 100%;
  }
  
  /* Individual Income Source */
  .income-source {
    background-color: #002b5c; /* Slightly lighter navy */
    padding: 2rem;
    border-radius: 10px;
    width: 300px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .income-source:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
  
  /* Icon Styles */
  .source-icon {
    font-size: 3rem;
    color: #ffcc00; /* Accent Color */
    margin-bottom: 1rem;
    transition: color 0.3s ease;
  }
  
  .income-source:hover .source-icon {
    color: #ffffff;
  }
  
  /* Income Source Title */
  .income-source h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-family: 'Poppins', sans-serif;
  }
  
  /* Income Source Description */
  .income-source p {
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
    line-height: 1.4;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes slideDown {
    from { transform: translateY(-50px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }
  
  @keyframes zoomIn {
    from { transform: scale(0.8); opacity: 0; }
    to { transform: scale(1); opacity: 1; }
  }
  
  @keyframes fadeInUp {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .income-source {
      width: 45%;
    }
  }
  
  @media (max-width: 768px) {
    .monetization-container {
      padding: 3rem 1.5rem;
    }
    
    .monetization-title {
      font-size: 2.5rem;
    }
  
    .monetization-description {
      font-size: 1rem;
    }
  
    .monetization-subtitle {
      font-size: 1.8rem;
    }
  
    .income-source {
      width: 80%;
    }
  }
  
  @media (max-width: 480px) {
    .monetization-title {
      font-size: 2rem;
    }
  
    .monetization-description {
      font-size: 0.95rem;
    }
  
    .monetization-subtitle {
      font-size: 1.5rem;
    }
  
    .income-source {
      width: 100%;
    }
  }
  