/* src/components/SectionA/SectionA.css */

.section-a {
  position: relative;
  padding: 4rem 1rem;
  margin-top: 2rem;
  overflow: hidden;
  background-color: #02053c; /* Dark background */
  color: #ffffff; /* White text for contrast */
  font-family: 'Manrope', sans-serif;
}

/* Particle Background */
.particles-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  /* Example using a gradient; replace with particle animations as needed */
  background: linear-gradient(135deg, #2c2c54, #0f0c29);
}

/* Main Content Container */
.content-container {
  text-align: center;
  padding: 20px;
  background-color: rgba(21, 12, 66, 0.85); /* Semi-transparent for depth */
  border-radius: 15px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.5);
  max-width: 900px;
  margin: auto;
  position: relative;
  z-index: 1;
}

/* AI Assistant Icon */
.ai-assistant img {
  width: 100px;
  height: auto;
  margin-bottom: 1rem;
  animation: float 6s ease-in-out infinite;
}

/* Floating Chatbot Icon */
.floating-chatbot img {
  width: 60px;
  height: 60px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  animation: bounce 2s infinite;
}

/* Search Button */
.search-button {
  background: linear-gradient(45deg, #00c6ff, #0072ff);
  color: #fff;
  border-radius: 25px;
  padding: 0.75rem 2rem;
  margin-bottom: 2rem;
  text-transform: none;
  font-weight: bold;
  font-size: 16px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: background 0.3s, transform 0.3s;
}

.search-button:hover {
  background: linear-gradient(45deg, #0056b3, #003f7f);
  transform: translateY(-5px);
}

/* Welcome Text */
.welcome-text {
  font-weight: 700;
  color: #00c6ff;
  font-size: 3.5rem;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
}

/* Description Text */
.description-text {
  font-weight: 500;
  color: #a0a0a0;
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

/* Authentication Buttons */
.auth-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.auth-buttons a {
  text-decoration: none;
}

.buyer-button,
.seller-button {
  border-radius: 25px;
  padding: 0.75rem 2rem;
  font-size: 16px;
  font-weight: 600;
  text-transform: none;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: background 0.3s, border 0.3s, transform 0.3s;
  margin: 0.5rem;
}

.buyer-button {
  background: linear-gradient(45deg, #ff416c, #ff4b2b);
  color: #fff;
  border: none;
}

.buyer-button:hover {
  background: linear-gradient(45deg, #c70039, #ff4b2b);
  transform: translateY(-5px);
}

.seller-button {
  background: transparent;
  color: #ff416c;
  border: 2px solid #ff416c;
}

.seller-button:hover {
  background: rgba(255, 65, 108, 0.1);
  border: 2px solid #c70039;
  transform: translateY(-5px);
}

/* OR Text */
.or-text {
  color: #a0a0a0;
  font-weight: 550;
  font-size: 16px;
  margin: 0 1rem;
  display: flex;
  align-items: center;
}

/* Free Trial Text */
.free-trial-text {
  margin-top: 1rem;
  color: #a0a0a0;
  font-weight: 550;
  font-size: 0.875rem;
}

/* Floating Chatbot Hover Effect */
.floating-chatbot:hover img {
  transform: scale(1.1);
}

/* Animations */
@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-15px);
  }
  60% {
    transform: translateY(-7px);
  }
}

/* Responsive Styles */
@media (max-width: 768px) {
  .welcome-text {
    font-size: 2.5rem;
  }

  .description-text {
    font-size: 1rem;
  }

  .search-button,
  .buyer-button,
  .seller-button {
    width: 100%;
    padding: 0.75rem 1rem;
    font-size: 14px;
  }

  .auth-buttons {
    flex-direction: column;
  }

  .or-text {
    display: none;
  }
}

.particles-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
}
