/* SellersCarousel.css */

/* Section Styling */
.sellers-carousel-section {
    padding: 1rem 0.5rem; /* Further reduced padding for smaller height */
    background-color: #001f3f; /* Dark Navy Background */
    text-align: center;
  }
  
  /* Carousel Title */
  .carousel-title {
    font-size: 1.5rem; /* Reduced font size */
    margin-bottom: 1rem; /* Reduced margin */
    font-family: 'Poppins', sans-serif;
    color: #ffffff; /* White Text for Contrast */
  }
  
  /* Carousel Container */
  .carousel-container {
    overflow: hidden;
    position: relative;
    padding: 0 2rem; /* Added padding to center the carousel items */
  }
  
  /* Carousel Track */
  .carousel-track {
    display: flex;
    width: calc(200% - 4rem); /* Adjusted width to account for padding */
    animation: scroll 12s linear infinite; /* Faster scroll */
    gap: 0.5rem; /* Reduced gap between carousel items */
  }
  
  /* Scroll Animation */
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
  
  /* Carousel Item */
  .carousel-item {
    flex: 0 0 12.5%; /* 8 items visible at a time */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #ffffff; /* White Text */
    padding: 0.25rem; /* Further reduced padding */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .carousel-item:hover {
    transform: translateY(-3px); /* Slight lift on hover */
    box-shadow: 0 3px 10px rgba(255, 204, 0, 0.2); /* Subtle accent shadow */
  }
  
  /* Seller Image */
  .seller-image {
    width: 60px; /* Further reduced size */
    height: 60px; /* Further reduced size */
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #ffffff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease, transform 0.3s ease;
  }
  
  .carousel-item:hover .seller-image {
    border-color: #ffcc00; /* Accent Color */
    transform: scale(1.03);
  }
  
  /* Seller Name */
  .seller-name {
    margin-top: 0.3rem; /* Reduced margin */
    font-size: 0.8rem; /* Further reduced font size */
    font-family: 'Poppins', sans-serif;
    color: #ffffff; /* White Text */
  }
  
  /* Responsive Design */
  
  /* Medium Screens */
  @media (max-width: 1200px) {
    .carousel-item {
      flex: 0 0 16.66%; /* 6 items visible */
    }
  
    .seller-image {
      width: 55px;
      height: 55px;
    }
  
    .seller-name {
      font-size: 0.75rem;
    }
  
    .carousel-title {
      font-size: 1.4rem;
    }
  }
  
  /* Small Screens */
  @media (max-width: 768px) {
    .carousel-item {
      flex: 0 0 25%; /* 4 items visible */
    }
  
    .seller-image {
      width: 50px;
      height: 50px;
    }
  
    .seller-name {
      font-size: 0.7rem;
    }
  
    .carousel-title {
      font-size: 1.3rem;
    }
  }
  
  /* Extra Small Screens */
  @media (max-width: 480px) {
    .carousel-item {
      flex: 0 0 33.33%; /* 3 items visible */
    }
  
    .seller-image {
      width: 45px;
      height: 45px;
    }
  
    .seller-name {
      font-size: 0.65rem;
    }
  
    .carousel-title {
      font-size: 1.2rem;
    }
  }
  
  /* Smooth Scroll Behavior */
  .carousel-container {
    scroll-behavior: smooth;
  }
  