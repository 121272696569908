/* index.css */

/* Import a Google Font */
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600&display=swap');

body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  background-color: #f9fafb;
  color: #333;
}

/* Keyframe Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Utility Classes */
.animate-fade-in {
  animation: fadeIn 1s ease forwards;
}

.animate-slide-in-left {
  animation: slideInFromLeft 1s ease forwards;
}
